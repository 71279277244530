<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      class="section1 small"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="mt-16"
        >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-16 mt-16">
          <div
            class="travel-text mt-0"
          >
            Who needs <br> business insurance?
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section2">
      <v-row>
        <v-col class="mt-16">
          <v-spacer class="mt-16"></v-spacer>
        </v-col>
      </v-row>
    </div>
    <div class="section3 pb-12">
      <v-row>
        <v-col cols="6">
          <div class="insurance mt-10 ml-16">
            Business Insurance
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col cols="9">
          <div class="sec3-sub mt-10 ml-16 pb-16">
            If you are a business owner then you may want to consider a business insurance policy.
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section4 mt-n16 pb-16">
      <v-row class="pb-12">
        <v-col
          cols="7"
          class="mt-16 pr-n8"
        >
          <v-img
            src="/img/model-business.png"
            class="travel-fam"
          ></v-img>
        </v-col>
        <v-col
          cols="5"
          class=" mt-4 ml-n8"
          style="z-index: 5;"
        >
          <div
            class="free-look pt-4 mt-12"
          >
            The policy is made up of three main coverage areas: <br> <br>
          </div>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Property coverage - Pays to repair or replace your buildings, furniture, equipment or computers destroyed or damaged in a fire or other incident.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Business income coverage - Reimburses loss of income if your business is disrupted due to a fire or other incident. Also pays extra expenses related to operating out of a temporary location.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Liability coverage - Pays if your company is responsible for injury or property damage caused by trips and falls, product mishaps, faulty installations or other errors.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Where a general liability policy is covered within this policy, a general liability policy for your business does not include property coverage or business income coverage.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="section5">
      <div class="clip"></div>
      <v-row justify="center">
        <v-col
          cols="8"
          class="d-flex justify-center"
        >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.section1{
  position: relative;
  width: 100%;
  background: url("/img/business-learnmore-background.png") no-repeat;
  background-size: cover;
}

.section2 {
    background-color: #FFFFFF;
}

.section3 {
    background-color: #DEECF1;
}

.section4 {
    background-color: #FFFFFF;
}

.covid-coverage-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 75rem;
}

.covid-coverage-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 90rem;
}

.free-look-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 75rem;
}

.free-look-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 90rem;
}

.sec3-sub {
font-size: 28px;
line-height: 132.19%;
color: #757575;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;

color: #616161;
}

.insurance {
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;
color: #757575;
}

.ins-card {
    background-color: #EEEEEE;
    width: 425px;
    height: 252px;
}

.learn {
  border: 1px solid #00A1B7 !important;
}

.sec4-txt {
    font-size: 55px;
line-height: 64px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.plan-img {
    max-width: 155px;
    height: auto;
    object-fit: scale-down;
}

.coverage-img {
    max-width: 130px;
    height: auto;
    object-fit: scale-down;
}

.payout-img {
    max-width: 145px;
    height: auto;
    object-fit: scale-down;
}

.customercare-img {
    max-width: 136px;
    height: 119px;
    object-fit: scale-down;
}
.sec4-subtxt {
font-weight: 500;
font-size: 24px;
line-height: 32px;
text-align: center;
text-transform: capitalize;
color: #212121;
}

.sec4-info {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #757575;
}

.section5 {
    height: 650px;
    position: relative;
    background: rgba(216, 253, 215, 0.5);
    font-size: 18px;
line-height: 26px;
color: #757575;
margin-bottom: -10rem;
}

.clip {
  height: 200px;
  background-color: #FFFFFF;
  clip-path: ellipse(50% 20% at 50% 0%);
}

.section5-txt{
font-size: 26px;
line-height: 42px;
text-align: center;
color: #757575;
}

.small {
    height: 700px;
}

.large {
    height: 900px;
}

.travel-fam {
    max-width: 800px;
    height: 741px;
    object-fit: scale-down;
}

.free-look {
    font-weight: 500;
font-size: 36px;
line-height: 42px;
color: #757575;
}

.free-look-txt {
font-size: 24px;
line-height: 33px;
font-feature-settings: 'liga' off;
color: #757575;
}

.btm-txt {
font-size: 20px;
line-height: 26px;
text-align: center;
color: #757575;
}

.home-icon-small {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 75rem;
}
.home-icon-large {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 87rem;
}
.home-underline-small {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 87.2rem;
}
.home-underline-large {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 99.2rem;
}

.main-cvg-txt {
    font-size: 55px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #757575;
}

.extras-title {
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.extras-text {
    font-weight: 700;
    font-size: 26px;
    line-height: 157.69%;
    color: #212121;
    background-color: #EDF8FA;
    height: 64px;
    width: 100%;
}
</style>
